<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div>
          <vx-card title="บัญชีต้นทาง">
            <div class="my-1">
              <h5>เลือกหมวดบัญชี</h5>
              <vs-select
                autocomplete
                class="w-full mt-4"
                v-model="fromCategory"
              >
                <vs-select-item
                  :key="index"
                  :value="item.code"
                  :text="item.name"
                  v-for="(item, index) in categories"
                />
              </vs-select>
            </div>
            <div class="mt-5">
              <h5>เลือกบัญชีต้นทาง</h5>
              <vs-select autocomplete class="w-full mt-4" v-model="fromAccount">
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="
                    `${item.bank_code} ${item.bank_fullname} (${item.bank_number})`
                  "
                  v-for="(item, index) in fromAccountList"
                />
              </vs-select>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="บัญชีปลายทาง">
            <div class="my-1">
              <h5>เลือกหมวดบัญชี</h5>
              <vs-select autocomplete class="w-full mt-4" v-model="toCategory">
                <vs-select-item
                  :key="index"
                  :value="item.code"
                  :text="item.name"
                  v-for="(item, index) in categories"
                />
              </vs-select>
            </div>
            <div class="mt-5">
              <h5>เลือกบัญชีปลายทาง</h5>
              <vs-select autocomplete class="w-full mt-4" v-model="toAccount">
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="
                    `${item.bank_code} ${item.bank_fullname} (${item.bank_number})`
                  "
                  v-for="(item, index) in toAccountList"
                />
              </vs-select>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="จำนวนเงิน">
            <div class="mt-2">
              <h5 class="mb-4">กรุณาใส่จำนวนเงิน</h5>
              <vs-input
                :disabled="
                  this.isReadyToFuckingConfirm || this.isFuckingLoading
                "
                v-validate="'required|decimal'"
                v-model="amount"
                type="number"
              />
            </div>
            <vx-card class="mt-5">
              <div v-if="fromAccount && toAccount && amount" class="my-1">
                <p>โอนเงินจาก</p>
                <p>
                  <b>{{ fromAccount.bank_code }}</b>
                  {{ fromAccount.bank_fullname }} [{{
                    fromAccount.bank_number
                  }}]
                </p>
                <p>ไปยัง</p>
                <p>
                  <b>{{ toAccount.bank_code }}</b>
                  {{ toAccount.bank_fullname }} [{{ toAccount.bank_number }}]
                </p>
                <p v-if="isReadyToFuckingConfirm">
                  จำนวนเงิน <span class="text-danger">{{ amount }}</span> บาท
                </p>
                <p v-if="isReadyToFuckingConfirm && currentBalance">
                  ยอดคงเหลือ <span class="text-success">{{ 0 }}</span> บาท
                </p>
              </div>
            </vx-card>
            <div v-if="isReadyToFuckingConfirm" class="mt-4">
              <h5 class="mb-4">กรุณาใส่รหัส 2-Factor เพื่อทำการยืนยัน</h5>
              <vs-input
                v-validate="'required'"
                v-model.trim="otp"
                type="text"
              />
            </div>
            <div v-if="!isReadyToFuckingConfirm">
              <vs-button
                :disabled="
                  isFuckingLoading || !(fromAccount && toAccount && amount)
                "
                color="success"
                type="relief"
                class="mt-6"
                @click="verify"
                >โอนเงิน</vs-button
              >
            </div>
            <div v-else>
              <vs-button
                :disabled="
                  isFuckingLoading ||
                    !(fromAccount && toAccount && amount && otp)
                "
                color="success"
                type="relief"
                class="mt-6"
                @click="confirm"
                >ยืนยัน</vs-button
              >
            </div>
          </vx-card>
          <vx-card v-if="tempLog.length > 0" class="mt-4 pb-3" title="รายการ Payment ล่าสุด">
            <p>*สามารถเชคในประวัติการโยกเงินได้</p>
            <br>
            <p :key="item" v-for="item in tempLog">{{ item }}</p>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {},
  data () {
    return {
      tempLog: [],
      bankData: {},
      placeholderText: 'โปรดรอสักครู่...',
      categories: [
        {
          name: 'บัญชี Payment',
          code: 'bank_payment'
        },
        {
          name: 'บัญชีพัก',
          code: 'bank_temporary'
        },
        {
          name: 'บัญชีกดเงิน',
          code: 'bank_atm'
        },
        {
          name: 'บัญชีเบิก',
          code: 'bank_bill'
        },
        {
          name: 'บัญชีอื่นๆ',
          code: 'bank_oth'
        },
        {
          name: 'บัญชีฝาก SCBALL',
          code: 'bank'
        },
        {
          name: 'บัญชีฝาก SCBONLY',
          code: 'bank_scb'
        },
        {
          name: 'บัญชีฝาก KBANKONLY',
          code: 'bank_kbank_only'
        },
        {
          name: 'บัญชีฝาก KBANK',
          code: 'bank_kbank'
        },
        {
          name: 'บัญชีฝาก BAY',
          code: 'bank_bay'
        },
        {
          name: 'บัญชีถอน SCB',
          code: 'bank_transfer'
        },
        {
          name: 'บัญชีถอน KBANK',
          code: 'bank_transfer_kbank'
        },
        {
          name: 'บัญชีถอน KTB',
          code: 'bank_transfer_ktb'
        },
        {
          name: 'บัญชีถอน KKP',
          code: 'bank_out_kkp'
        }
      ],
      fromCategory: null,
      toCategory: null,
      fromAccount: null,
      toAccount: null,
      amount: null,
      otp: null,
      isFuckingLoading: false,
      isReadyToFuckingConfirm: false,
      uuid: null,
      currentBalance: null
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    fromAccountList () {
      return this.bankData[this.fromCategory]
    },
    toAccountList () {
      return this.bankData[this.toCategory]
    }
  },
  async mounted () {
    const fetch = await axios.get('/internal-transfer/list')
    if (fetch && fetch.data && fetch.data.success) {
      this.bankData = fetch.data.data
    } else {
      this.$vs.notify({
        time: 4000,
        color: 'danger',
        position: 'top-right',
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการดึงข้อมูล',
        text: this.status_wd.info
      })
    }
  },
  methods: {
    async verify () {
      if (this.isFuckingLoading) return false

      this.isFuckingLoading = true

      if (
        this.fromCategory === 'bank_atm' ||
        this.fromCategory === 'bank_bill' ||
        this.fromCategory === 'bank_oth'
      ) {
        this.fromCategory = 'bank_temporary'
      }

      if (
        this.toCategory === 'bank_atm' ||
        this.toCategory === 'bank_bill' ||
        this.toCategory === 'bank_oth'
      ) {
        this.toCategory = 'bank_temporary'
      }

      const reqVerify = await axios.post('/internal-transfer/verify', {
        fromCategory: this.fromCategory,
        fromAccount: this.fromAccount,
        toCategory: this.toCategory,
        toAccount: this.toAccount,
        amount: this.amount
      })

      if (reqVerify && reqVerify.data && reqVerify.data.success) {
        const { uuid } = reqVerify.data.data
        this.uuid = uuid
        this.isReadyToFuckingConfirm = true
        this.$vs.notify({
          time: 4000,
          color: 'success',
          position: 'top-right',
          icon: 'success',
          title: 'กรุณายืนยันการโยกเงิน',
          text: reqVerify.data.message
        })
      } else {
        this.$vs.notify({
          time: 4000,
          color: 'danger',
          position: 'top-right',
          icon: 'danger',
          title: 'เกิดข้อผิดพลาดในการทำรายการ',
          text: reqVerify.data.message
        })
      }

      this.isFuckingLoading = false
    },
    async confirm () {
      if (this.isFuckingLoading) return false

      this.isFuckingLoading = true

      const reqConfirm = await axios.post('/internal-transfer/confirm', {
        fromCategory: this.fromCategory,
        fromAccount: this.fromAccount,
        toCategory: this.toCategory,
        toAccount: this.toAccount,
        amount: this.amount,
        otp: this.otp,
        uuid: this.uuid
      })

      if (reqConfirm && reqConfirm.data && reqConfirm.data.success) {
        this.$vs.notify({
          time: 4000,
          color: 'success',
          position: 'top-right',
          icon: 'success',
          title: 'โยกเงินสำเร็จ',
          text: reqConfirm.data.message
        })
        if (reqConfirm.data.uuid) this.tempLog.push(reqConfirm.data.uuid)
        this.clearData()
      } else {
        this.$vs.notify({
          time: 4000,
          color: 'danger',
          position: 'top-right',
          icon: 'danger',
          title: 'โยกเงินไม่สำเร็จ',
          text: reqConfirm.data.message
        })
        this.isReadyToFuckingConfirm = false
      }

      this.otp = null
      this.isFuckingLoading = false
    },
    clearData () {
      this.amount = null
      this.otp = null
      this.isFuckingLoading = false
      this.isReadyToFuckingConfirm = false
      this.uuid = null
      this.currentBalance = null
    }
  }
}
</script>

<style lang="scss"></style>
